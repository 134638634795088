import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { SectionTwoThirdsRight } from '../components/SectionTwoThirdsRight'

export default class JobsPage extends React.Component {
  render() {
    const { data, location } = this.props

    const allMarkdownRemark = data.allMarkdownRemark
    const edges = allMarkdownRemark.edges

    return (
      <Layout type={'home'} location={location} title="Jobs" description="Work with us">
        <div className="bg-light-gray">
          <div className="mw7-m mw8-l center pv5-ns ph3 pv-1px-s">
            <SectionTwoThirdsRight label="JOBS">
              Are you looking for a design internship? <br /> Are you a Front End developer? <br />{' '}
              Do you live in Milan and you love data visualizations? Join our team!
            </SectionTwoThirdsRight>
          </div>
        </div>

        <div className="bg-yellow">
          <div className="mw7-m mw8-l center pv4  pv5-ns ph3 flex-l">
            <div className="w-33-l">
              <div className="normal ttu tracked mono f5 pb4 pb0-ns">OPEN POSITIONS</div>
            </div>
            <div className="w-66-ns">
              <ul className="list ma0 pa0">
                {edges.map((edge, i) => {
                  const { title, path, location } = edge.node.frontmatter
                  const padding = i !== edges.length - 1 ? 'pv3' : 'pt3'

                  return (
                    <li key={i}>
                      <a
                        className={`flex-m flex-l db items-center justify-between bt near-black no-underline b--near-black ${padding}`}
                        href={path}
                        title="Apply"
                      >
                        <div className="ma0 f5 normal w-50-ns">{title}</div>
                        <div>{location}</div>
                        <div className="dib mt3 mt0-ns btn-link">Apply</div>
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { layout: { eq: "job" }, published: { eq: true } } }) {
      edges {
        node {
          frontmatter {
            title
            path
            layout
            location
          }
        }
      }
    }
  }
`
